import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import Layout from '../components/layout/Layout';

import gurleen from '../assets/gurleen.jpeg';

import './pages-css/about.css';



const about = () => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Learning Curve | About</title>
            </Helmet>
            <Fragment>
                <div className="bg-about">
                    <div style={{height: '100%'}} className="container pt-5 pb-2">
                        <div style={{height: '100%'}} className="align-center">
                            <div style={{height: '100%'}} className="row">
                                <div className="center-vert">
                                    <h1 className="text-white bold">An experience designed to help you grow.</h1>
                                    <p style={{fontSize: '1.4em'}} className="text-clean p-3">Learning Curve is a tutoring service run by Ontario University students who know how to help young learners take their education to the next level. Whether that is continous academic success in high school or an extra push for post-secondary education. Who would know better than students who have already done it?</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                

                <div className="bg-main">
                    <div className="container">
                        <div className="py-5">
                            <div className="row py-3">
                                <div className="col-md-4 py-3">
                                    <div className="align-center fade-up">
                                        <i style={{fontSize: '5em'}} className="fas fa-graduation-cap pb-2 text-clean"></i>
                                        <p style={{fontSize: '1.2em'}} className="text-white">Designed to accommodate all high school students looking for academic success</p>
                                    </div>
                                </div>
                                <div className="col-md-4 py-3">
                                    <div className="align-center fade-up">
                                    <i style={{fontSize: '5em'}} className="fas fa-address-book pb-2 text-clean"></i>
                                        <p style={{fontSize: '1.2em'}} className="text-white">We offer customized lesson plans based on students’ level</p>
                                    </div>
                                </div>
                                <div className="col-md-4 py-3">
                                    <div className="align-center fade-up">
                                        <i style={{fontSize: '5em'}} className="fas fa-tools pb-2 text-clean"></i>
                                        <p style={{fontSize: '1.2em'}} className="text-white">We strive for excellence using 21st century tools that are essential for learning acquisition</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-around">
                                <div className="col-md-4 py-3">
                                    <div  className="align-center fade-up">
                                        <i style={{fontSize: '5em'}} className="fas fa-chalkboard-teacher pb-2 text-clean"></i>
                                        <p style={{fontSize: '1.2em'}} className="text-white">Schools struggle to provide sufficient opportunities for students, so we are trained to help the learning process</p>
                                    </div>
                                </div>
                                <div className="col-md-4 py-3">
                                    <div className="align-center fade-up">
                                        <i style={{fontSize: '5em'}} className="fas fa-university pb-2 text-clean"></i>
                                        <p style={{fontSize: '1.2em'}} className="text-white">We prepare students for post-secondary admissions and success</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                    
                <h1 className="align-center text-dark py-5 bg-yellow bold">Instructor Profiles:</h1>
            
                <div className="container">
                    <div className="row py-3">
                        <div className="col-lg-9 order-lg-12 center-vert">
                            <div className="card shadow p-4">
                                <div>
                                    <h5 className="text-dark display-inline-block mr-3">Name:</h5>
                                    <p style={{fontSize: '1.2em'}} className="text-secondary display-inline-block ">Gurleen Dhillon</p>
                                </div>
                                <div>
                                    <h5 className="text-dark display-inline-block mr-3">Qualifications:</h5>
                                    <ul style={{fontSize: '1.2em'}} className=" text-secondary">
                                        <li>Bachelor of Commerce at University of Toronto</li>
                                        <li>Accounting Specialist</li>
                                        <li>Functional French Minor</li>
                                    </ul>
                                    <h5 className="text-dark display-inline-block mr-3">Highschool Experience:</h5>
                                    <ul style={{fontSize: '1.2em'}} className="text-secondary">
                                        <li>Ontario Secondary School Diploma</li>
                                        <li>North Park Secondary School</li>
                                        <li>IBT Program and Business SHSM</li>
                                        <li>Honors Student</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 order-lg-1">
                            <div className="card shadow">
                                <img className="instructor" style={{width: '100%', borderRadius: '0.2rem'}} src={gurleen} alt=""/>
                            </div>
                        </div>
                    </div>
                    
                </div>
            
            
            </Fragment>
        </Layout>
        

    )
}

export default about
